// app/javascript/controllers/payment_options_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkbox", "collapse", "collapseBtn"];

  connect() {
    this.toggle()
  }

  toggle = (event) => {
    const checkbox = this.checkboxTarget;
    const targetCollapse = this.collapseTarget;
    const targetCollapseBtn = this.collapseBtnTarget;
    const checked = checkbox.checked;

    $(targetCollapse).collapse(event && checked ? "show" : "hide");
    targetCollapseBtn.classList.toggle("disabled", !checked);

    targetCollapse.querySelectorAll('input').forEach(input => {

      if (targetCollapse.id.includes("pay_in_full") && !checked) {
        if (input.id.includes("discount")) input.value = 0;
      } else {
        input.disabled = !checked;
      }

      if (!targetCollapse.id.includes("third_party")) {
        input.required = checked;
      }
    });
  }
}
