import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "payInFullDiscountOutput",
    "payInFullDiscountInput",
    "payInFullDue",
    "payInFullPayment",
    "payInFullDescription",
    "totalDollars",
    "depositThenBalanceDepositInput",
    "depositThenBalanceRemainingInput",
    "depositThenBalanceDepositOutput",
    "depositThenBalanceDescription",
    "minimumDepositPercentage",
    "depositThenBalanceDue",
    "merchantFeeAssignmentType",
    "patientMerchantFeeOutput",
    "practiceMerchantFeeOutput",
    "payInFullTotalDue",
    "depositThenBalanceDepositInstallmentDues",
    "depositThenBalanceBalanceInstallmentDues",
    "practiceDepositMerchantFeeOutput",
    "patientDepositMerchantFeeOutput",
    "practiceBalanceMerchantFeeOutput",
    "patientBalanceMerchantFeeOutput",
    "paymentScheduleTypeOutput",
    "paymentScheduleTypeInput",
    "paymentDeliveryTypeInput",
    "totalMerchantFeeOutput",
    "merchantFeePercentage",
    "merchantFeePercentageOutput",
    "patientDollars",
    "insuranceDollars",
    "customPrice",
    "merchantFeeOption",
    "merchantFeePriceOutput",
    "merchantFeeDescription",
    "treatmentPlanPatientDollars",
    "treatmentPlanInsuranceDollars",
    "txpLineItemFields",
    "txpTitleField",
    "thirdPartyDescription",
    "sixMonthFinancingAmount",
    "sixMonthFinancingDescription",
    "sixMonthFinancingCheckbox",
    "twelveMonthFinancingAmount",
    "twelveMonthFinancingDescription",
    "twelveMonthFinancingCheckbox",
    "eighteenMonthFinancingAmount",
    "eighteenMonthFinancingDescription",
    "eighteenMonthFinancingCheckbox",
    "twentyFourMonthFinancingAmount",
    "twentyFourMonthFinancingDescription",
    "twentyFourMonthFinancingCheckbox",
  ]

  connect() {
    this.updateTotalAmount = this.updateTotalAmount.bind(this);
    $(this.element).on("change", this.updateTotalAmount);

    $(this.element).on('cocoon:after-insert', this.updateTotalAmount)
    $(this.element).on('cocoon:after-remove', this.updateTotalAmount)

    $(this.element).on('cocoon:after-remove', function (e, removedItem) {
      removedItem.find(':input[required]').removeAttr('required');
    });

    if (this.hasTxpTitleFieldTarget) {
      this.setTitle = this.setTitle.bind(this);
      $(this.element).on('cocoon:after-insert', this.setTitle)
      $(this.element).on('cocoon:after-remove', this.setTitle)
      this.setTitle()
    }
  }

  payInFullCalculations() {
    const payInFullDiscountInputValue = parseFloat(this.payInFullDiscountInputTarget.value) || 0
    const totalDollarsValue = parseFloat(this.totalDollarsTarget.value) || 0

    this.payInFullDueTarget.innerHTML = `$${totalDollarsValue}`;
    this.payInFullDiscountOutputTarget.innerHTML = `-$${payInFullDiscountInputValue}`;
    this.payInFullPaymentTarget.innerHTML = `$${totalDollarsValue - payInFullDiscountInputValue}`;
    this.payInFullDescriptionTarget.innerHTML = `Discount: $${payInFullDiscountInputValue}`;

  }

  thirdPartyCalculations() {
    const totalDollarsValue = parseFloat(this.totalDollarsTarget.value) || 0;

    const financingOptions = [
      { months: 6, amountTarget: this.sixMonthFinancingAmountTarget, descriptionTarget: this.sixMonthFinancingDescriptionTarget, checkboxTarget: this.sixMonthFinancingCheckboxTarget },
      { months: 12, amountTarget: this.twelveMonthFinancingAmountTarget, descriptionTarget: this.twelveMonthFinancingDescriptionTarget, checkboxTarget: this.twelveMonthFinancingCheckboxTarget },
      { months: 18, amountTarget: this.eighteenMonthFinancingAmountTarget, descriptionTarget: this.eighteenMonthFinancingDescriptionTarget, checkboxTarget: this.eighteenMonthFinancingCheckboxTarget },
      { months: 24, amountTarget: this.twentyFourMonthFinancingAmountTarget, descriptionTarget: this.twentyFourMonthFinancingDescriptionTarget, checkboxTarget: this.twentyFourMonthFinancingCheckboxTarget }
    ];

    financingOptions.forEach(option => {
      const { months, amountTarget, descriptionTarget, checkboxTarget } = option;
      if (amountTarget && descriptionTarget) {
        const amount = (totalDollarsValue / months).toFixed(2);
        amountTarget.value = amount;
        descriptionTarget.innerHTML = `$${amount} / mo`;
      }
    });

    const checkedOptions = financingOptions.filter(option => option.checkboxTarget && option.checkboxTarget.checked);
    const checkedMonths = checkedOptions.map(option => option.months);

    if (checkedMonths.length === 0) {
      this.thirdPartyDescriptionTarget.innerHTML = `None`;
    }
    else if (checkedMonths.length === 1) {
      this.thirdPartyDescriptionTarget.innerHTML = `${checkedMonths[0]} months`;
    } else {
      this.thirdPartyDescriptionTarget.innerHTML = `${checkedMonths.slice(0, -1).join(', ')} or ${checkedMonths.slice(-1)} months`;
    }
  }


  depositThenBalanceCalculations() {
    const minimumDepositPercentage = parseFloat(this.minimumDepositPercentageTarget.value) || 0;
    const depositThenBalanceDepositInputValue = parseFloat(this.depositThenBalanceDepositInputTarget.value) || 0;
    const totalDollarsValue = parseFloat(this.totalDollarsTarget.value);
    const depositThenBalanceRemainingValue = totalDollarsValue - depositThenBalanceDepositInputValue;
    const minimumDepositValue = totalDollarsValue * (minimumDepositPercentage / 100)

    this.depositThenBalanceRemainingInputTarget.value = depositThenBalanceRemainingValue
    this.depositThenBalanceDepositOutputTarget.innerHTML = `$${depositThenBalanceDepositInputValue}`;
    this.depositThenBalanceDueTarget.innerHTML = `$${depositThenBalanceRemainingValue}`;
    this.depositThenBalanceDescriptionTarget.innerHTML = `Deposit: $${depositThenBalanceDepositInputValue}`;
  }


  handleChange(e) {
    const selector = e.target.id.replace("payment_delivery_type_", "").replace("_fee_desc", "");

    if (e.target.value === "in_office") {
      $(`#merchantFeeAssignmentType_${selector}_container :input`).prop("disabled", true);
      $(`#merchantFeeAssignmentType_${selector}_container`).addClass("d-none");
    } else {
      $(`#merchantFeeAssignmentType_${selector}_container :input`).prop("disabled", false);
      $(`#merchantFeeAssignmentType_${selector}_container`).removeClass("d-none");
    }
  }

  handleMerchantFee() {
    if (!this.hasTotalDollarsTarget &&
      !this.hasTotalMerchantFeeTarget &&
      !this.hasMerchantFeePercentageTarget &&
      !this.hasTotalMerchantFeeOutputTarget &&
      !this.hasMerchantFeePercentageOutputTarget &&
      !this.hasMerchantFeePriceOutputTarget &&
      !this.hasMerchantFeeOptionTarget
    ) return;

    let totalMerchantFeeOutput = this.totalMerchantFeeOutputTarget
    let merchantFeePercentageOutput = this.merchantFeePercentageOutputTarget
    let merchantFeePriceOutput = this.merchantFeePriceOutputTarget
    let merchantFeeDescription = this.merchantFeeDescriptionTarget

    let totalDollars = this.totalDollarsTarget.value
    let merchantFeePercentage = this.merchantFeePercentageTarget.value
    let merchantFeeType = $(this.merchantFeeOptionTarget).find('input[type="radio"]:checked').val()

    // Sanitize values
    totalDollars = parseFloat(totalDollars)
    merchantFeePercentage = parseFloat(merchantFeePercentage)

    switch (merchantFeeType) {
      case 'practice': merchantFeePercentage = 0
        break;
      case 'share': merchantFeePercentage /= 2
        break;
    }

    totalMerchantFeeOutput.innerHTML = converter.convertFloatToCurrency(merchantFeePercentage * totalDollars)
    merchantFeePriceOutput.innerHTML = converter.convertFloatToCurrency(totalDollars)
    merchantFeePercentageOutput.innerHTML = (merchantFeePercentage * 100).toFixed(2) + '%'
    merchantFeeDescription.innerHTML = `Patient pays ${converter.convertFloatToCurrency(merchantFeePercentage * totalDollars)}`
  }

  updateTotalAmount() {
    if (!this.hasPatientDollarsTarget && !this.hasInsuranceDollarsTarget) return;

    let patientDollars = 0
    let insuranceDollars = 0

    $(this.patientDollarsTargets).filter(':visible').each(function () {
      patientDollars += converter.convertCurrencyToFloat($(this).val()) || 0;
    });

    $(this.insuranceDollarsTargets).filter(':visible').each(function () {
      insuranceDollars += converter.convertCurrencyToFloat($(this).val()) || 0;
    });

    if (this.hasTreatmentPlanPatientDollarsTarget) this.treatmentPlanPatientDollarsTarget.value = patientDollars.toFixed(2);
    if (this.hasTreatmentPlanInsuranceDollarsTarget) this.treatmentPlanInsuranceDollarsTarget.value = insuranceDollars.toFixed(2);
    if (!this.hasTotalDollarsTarget) return;

    this.totalDollarsTarget.value = patientDollars.toFixed(2);
    this.payInFullCalculations();
    this.depositThenBalanceCalculations();
    this.thirdPartyCalculations();
    this.handleMerchantFee();
    if (this.hasCustomPriceTarget) this.customPriceTarget.value = patientDollars.toFixed(2);
  }

  setTitle() {
    if (!this.hasPatientDollarsTarget && !this.hasInsuranceDollarsTarget) return;

    const txpTitleField = this.txpTitleFieldTarget;
    const txpLineItemsSize = $(this.patientDollarsTargets).filter(':visible').length;

    if (txpLineItemsSize <= 1) {
      txpTitleField.classList.add('d-none');
      txpTitleField.querySelectorAll('input').forEach(input => {
        input.disabled = true;
        input.required = false;
      });
    } else {
      txpTitleField.classList.remove('d-none');
      txpTitleField.querySelectorAll('input').forEach(input => {
        input.disabled = false;
        input.required = true;
      });
    }
  }
}
