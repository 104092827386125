import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal"]
  static values = {
    id: String,
    streamId: String,
    tenantId: String,
    toggle: String
  }

  hideModal() {
    this.modalTarget.classList.remove('show');
    $(".modal-backdrop").remove()
    $(this.modalTarget).modal('hide');
    if (this.hasToggleValue && this.toggleValue === "toggle") {
      $('#mainModalDrawer').modal('show');
    }
  }

  submitEnd(e) {
    //restore turbo stream id
    if (this.hasStreamIdValue)
      $($("#" + e.target.id).parents("turbo-frame")).attr("id", this.streamIdValue)

    if (e.detail.success && (e.target.id == this.idValue)) {
      var submitter = e.detail.formSubmission.submitter;
      if (!submitter || !submitter.classList.contains("not-submitter"))
        this.hideModal()
    }
  }

  beforeSubmit(e) {
    if (e.target.id == this.idValue) {
      if (this.hasTenantIdValue) {
        // bypass tenant in consultant practice show page for turbo request
        e.detail.formSubmission.fetchRequest.url.search += (!Boolean(e.detail.formSubmission.fetchRequest.url.search) ? '' : '&') + "tenant_id=" + this.tenantIdValue;
      }
      // remove turbo stream id to prevent broadcast to change current user UI
      this.streamIdValue = $($("#" + e.target.id).parents("turbo-frame")).attr("id")
      $($("#" + e.target.id).parents("turbo-frame")).attr("id", "")
    }
  }

  fetchResources(e) {
    $(e.currentTarget).addClass('disabled-view')
    var icon = e.currentTarget.querySelector("i");
    $(icon).removeClass('fa-sync');
    $(icon).addClass('fa-spinner fa-spin');

    var url = $(e.currentTarget).data("resourceFetchUrlValue")
    var that = this
    var current_element = e.currentTarget
    $.ajax({
      type: 'GET',
      url: url,
      dataType: 'script',
      success: function (result) {
        $(current_element).siblings("form")[0].requestSubmit()
        $(current_element).removeClass('disabled-view')
        $(icon).removeClass('fa-spinner fa-spin');
        $(icon).addClass('fa-sync');
      }
    });
  }
}
