import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "leaderboardDate",
    "leaderboardForm",
    "leaderboardCard",
    "leaderboardLoader",
    "leaderboardTable",
    "datePickerType",
    "datePicker"
  ];

  static values = {
    enableTableAfterCall: Boolean
  }

  datePickerTypeTargetConnected(element) {
    this.setupDatepicker();
  }

  leaderboardTableTargetConnected(element) {
    if (element.dataset.leaderboardEnableTableAfterCallValue) {
      var that = this
      $(document).on("ajaxStop", () => {
        that.setupDataTable();
        that.setupPopoversAndTooltips();
      });
    } else {
      try {
        this.setupDataTable();
      } catch (error) {
        console.error(error);
      }
    }
    this.setupPopoversAndTooltips();
    this.setupLeaderboard();
  }

  setupDatepicker = () => {
    const dateTypeInput = this.datePickerTypeTarget;
    if (!dateTypeInput) return;

    dateTypeInput.addEventListener("change", () => {
      this.updateDatepicker();
    });

    this.updateDatepicker();
  }

  updateDatepicker = () => {
    const dateTypeInput = this.datePickerTypeTarget;
    if (!dateTypeInput) return;

    const pickerValue = dateTypeInput.value;
    const datepickerInput = this.element.querySelector('.flatpicker input[type="text"]');
    datepickerInput.setAttribute("data-behavior", `flatpickr_${pickerValue}`);
    const datePickerID = $(this.datePickerTarget).attr('id')
    eval(`datetime_picker.${pickerValue}_input('${datePickerID}', true);`);
  }

  setupDataTable = () => {
    const leaderboardTable = this.leaderboardTableTarget;
    if (!leaderboardTable) return;

    let orderByCol = leaderboardTable.dataset?.leaderboardOrderIndexValue;
    if (!orderByCol) orderByCol = 1

    $.fn.dataTable.ext.type.order['custom-currency-pre'] = function (data) {
      var currencyMatch = data.match(/\$?\d+(?:,\d{3})*(?:\.\d{2})?|\d+\.\d+|\d+%?/)

      if (currencyMatch) {
        // Extract and return the numeric value for sorting
        return parseFloat(currencyMatch[0].replace(/[^0-9.-]/g, ''));
      } else {
        // Return 0 if no currency value found (or for non-link content)
        return 0;
      }
    };

    $(leaderboardTable).DataTable({
      retrieve: true,
      paging: false,
      searching: false,
      bInfo: false,
      order: [[orderByCol, "desc"]],
      columnDefs: [
        {
          targets: 1, // Second column (index 1)
          searchable: true,
          orderable: true,
          type: 'custom-currency', // Use the custom sorting type
          render: function (data, type, row) {
            // Implement custom search and ordering logic here
            if (type === 'filter' || type === 'sort') {
              // Extract link content and remove HTML tags
              var linkContent = data.replace(/<[^>]*>/g, '');

              // Return the link content for sorting
              return linkContent;
            } else {
              // Use the HTML content for display
              return data;
            }
          }
        }
      ],
      "footerCallback": function (row, data, start, end, display) {
        var api = this.api();

        // Initialize an array to hold the total for each column
        var columnTotals = [];

        // Iterate over each column in the footer row
        $(api.table().footer()).find('td.enable-sum').each(function (index) {
          var columnIndex = $(this).index();
          var total = { value: 0, prefix: '' }; // Object to store total value and prefix

          // Calculate the sum for the current column
          total.value = api
            .column(columnIndex, { page: 'current' })
            .data()
            .reduce(function (a, b) {
              // Use the text content of <a> tag if exists, otherwise use the text content of <td>
              if (b.includes('<a')) {
                var value = $(b).first('a').text().trim() || $(b).text().trim();
              } else {
                var value = b
              }

              // Match numbers with optional $, % symbols and commas
              var currencyMatch = value.match(/([$%]?)(\d+(?:,\d{3})*(?:\.\d+)?%?)/);

              if (currencyMatch) {
                total.prefix = currencyMatch[1]; // Save the prefix
                return a + parseFloat(currencyMatch[2].replace(/[^\d.%]/g, '')) || 0;
              }
              return a;
            }, 0);

          // Add the total object to the columnTotals array
          columnTotals[columnIndex] = total;
        });

        // Update footer with the totals for each column
        $(api.table().footer()).find('td.enable-sum').each(function (index) {
          var columnIndex = $(this).index();
          if (columnTotals[columnIndex] !== undefined) {
            $(this).text(columnTotals[columnIndex].prefix + columnTotals[columnIndex].value.toLocaleString());
          }
        });
      }
    });
  }

  renderDataTableCell = (data, type) => {
    if (type === 'filter' || type === 'sort') {
      const linkContent = data.replace(/<[^>]*>/g, '');
      return linkContent;
    }
    return data;
  }

  setupPopoversAndTooltips() {
    $('[data-toggle="popover"]').popover();
    $('[data-toggle="tooltip"]').tooltip();
  }

  setupLeaderboard = () => {
    if (!this.hasLeaderboardLoaderTarget) return;

    const leaderboardLoader = this.leaderboardLoaderTarget;
    const leaderboardCard = this.leaderboardCardTarget;

    $(leaderboardLoader).addClass('d-none');
    if (leaderboardCard) {
      $(leaderboardCard).removeClass('d-none');
    }

    this.leaderboardFormTarget.addEventListener('submit', (e) => {
      $(leaderboardLoader).removeClass('d-none');
      $(leaderboardLoader).html(Shared.get_loader);
      if (leaderboardCard) {
        leaderboardCard.classList.add('d-none');
      }
    });

    const leaderboardTable = this.leaderboardTableTarget;
    const leaderboardDate = this.leaderboardDateTarget;
    if (leaderboardDate && leaderboardTable) {
      leaderboardDate.innerHTML = leaderboardTable.dataset.leaderboardDateValue;
    }
  }
}
