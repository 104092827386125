import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    $(this.element).find("select:not(.no-select2)").select2();

    let datetimePickerId = this.element.dataset?.leaderboardOrderIndexValue;
    if (!datetimePickerId) datetimePickerId = 'appointment_at_picker'

    datetime_picker.range_input(datetimePickerId);
  }
}
