import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["totalAmount", "installments", "duration", "gap", "startDate", "installmentFields", "addPaymentOption"];

  generateInstallments() {
    const totalAmount = converter.convertCurrencyToFloat(this.totalAmountTarget.value) || 0;
    const numberOfInstallments = parseInt(this.installmentsTarget.value, 10);
    const gap = parseInt(this.gapTarget.value);
    const duration = this.durationTarget.value;
    const startDate = new Date(this.startDateTarget.value);

    if (isNaN(totalAmount) || isNaN(numberOfInstallments) || isNaN(startDate)) {
      alert("Please enter valid inputs.");
      return;
    }

    const installmentAmount = Math.ceil((totalAmount / numberOfInstallments) * 100) / 100;
    let installmentDate = new Date(startDate);
    const installmentDates = [];

    for (let i = 0; i < numberOfInstallments; i++) {
      let dueDate;
      if (duration === "days") {
        dueDate = new Date(installmentDate);
        dueDate.setDate(installmentDate.getDate() + (i * gap)); // Assuming each installment is 30 days apart
      } else if (duration === "weeks") {
        dueDate = new Date(installmentDate);
        dueDate.setDate(installmentDate.getDate() + (i * 7 * gap));
      } else if (duration === "months") {
        dueDate = new Date(installmentDate);
        dueDate.setMonth(installmentDate.getMonth() + (i * gap));
      }
      installmentDates.push({ amount: installmentAmount, date: dueDate.toISOString().split('T')[0] });
    }

    this.displayInstallments(installmentDates);
  }

  displayInstallments(installmentDates) {
    const nestedFields = this.installmentFieldsTarget.querySelectorAll('.nested-fields');

    nestedFields.forEach(field => {
      const removeButton = field.querySelector('a.remove_fields');
      if (removeButton) {
        removeButton.click();
      }
    });

    installmentDates.forEach((installment, index) => {
      const newField = document.createElement("div");
      newField.innerHTML = this.addPaymentOptionTarget.dataset.template
        .replace(/NEW_RECORD/g, new Date().getTime() + index)
        .replace(/INDEX/g, index)
        .replace(/AMOUNT_VALUE/g, installment.amount)
        .replace(/DATE_VALUE/g, installment.date);

      this.installmentFieldsTarget.appendChild(newField);
    });
  }
}
