import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.inputTargets.forEach(function (_target) {
      $(_target).on("change keyup", function (event) {
        if (event.key === '.') return;
        this.inputValues.bind(this)(event);
      }.bind(this));

      this.inputValues
    }.bind(this));

    this.inputValues();
    this.handleFormSubmission();
  }

  formatByGoal(element) {
    const unitsType = this.element.dataset.unitsType
    const suffix = this.element.dataset.suffix
    const abbreviate = this.element.dataset.abbreviate === "true";

    const numericValue = converter.convertCurrencyToFloat(element.value);
    if (!numericValue) return;

    const formattedValue = formatNumberByGoalType(unitsType, numericValue, suffix, abbreviate);
    element.value = formattedValue;
  }

  inputValues() {
    this.inputTargets.forEach((_target) => {
      this.formatByGoal(_target)
    });
  }

  handleFormSubmission() {
    const form = this.inputTarget.form;

    if (form) {
      form.addEventListener("submit", () => {
        this.inputTargets.forEach((_target) => {
          let numericValue = converter.convertCurrencyToFloat(_target.value) || 0;
          _target.value = numericValue.toString();
        });
      });
    }
  }
}

function formatNumberByGoalType(unitsType, value, suffix = null, abbreviate = false) {
  value = parseFloat(value) || 0;
  switch (unitsType) {
    case '$':
      if (suffix) {
        value = new Intl.NumberFormat().format(value);
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }).format(value);
      } else {
        return value = new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(value);
      }
    case '%':
      if (suffix) {
        return new Intl.NumberFormat('en-US', { style: 'percent', minimumFractionDigits: 0 }).format(value / 100);
      } else {
        value
      }
    default:
      if (suffix) {
        return value.toString() + " " + suffix;
      } else {
        return value.toString();
      }
  }
}
