// app/javascript/controllers/custom_schedule_fields_controller.js
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['fullPrice', 'remainingPrice', 'submitBtn', 'addPaymentBtn'];

  connect() {
    const fieldEnabled = this.element.dataset.fieldEnabled
    $(this.element.getElementsByTagName("input")).prop('disabled', fieldEnabled != "true");

    this.addCocoon = this.addCocoon.bind(this);
    $(this.element).on("cocoon:after-insert", this.addCocoon);

    this.removeCocoon = this.removeCocoon.bind(this);
    $(this.element).on("cocoon:after-remove", this.removeCocoon);

    this.observer = new MutationObserver(this.checkCocoons.bind(this));
    const targetElement = this.fullPriceTarget;
    const config = { childList: true, subtree: true };
    this.observer.observe(targetElement, config);

    this.checkCocoons();
  }

  disconnect() {
    this.observer.disconnect();
  }

  addCocoon() {
    const dateInputs = Array.from(this.element.querySelectorAll('.active.due-at-input')).filter(dateInput => {
      const parentDiv = dateInput.closest('.nested-fields');
      return parentDiv && getComputedStyle(parentDiv).display !== 'none';
    });

    // Find the last date input in the list
    const lastDateInput = dateInputs[dateInputs.length - 2];
    if (lastDateInput) {
      const lastDateValue = lastDateInput.value;

      if (isValidDate(lastDateValue)) {
        const newDate = new Date(lastDateValue);
        newDate.setDate(newDate.getDate() + 1); // Increment the date by one day

        const options = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = newDate.toLocaleDateString('en-US', options);

        // Set the new date value to the newly added cocoon
        const newDateInput = dateInputs[dateInputs.length - 1];
        if (newDateInput) {
          newDateInput.dataset.flatpickrDefaultDate = `${newDate.getFullYear()}-${padZero(newDate.getMonth() + 1)}-${padZero(newDate.getDate())}`
          newDateInput.value = formattedDate;
          newDateInput?.nextSibling?.classList?.remove('is-invalid');
        }
      }
    }

    this.checkCocoons();
  }

  removeCocoon() {
    this.checkCocoons();
  }

  changeAmount() {
    this.checkCocoons();
  }

  changeDate() {
    this.checkCocoons();
  }

  checkCocoons() {
    const amountInputs = Array.from(this.element.querySelectorAll('.amount-input')).filter(amountInput => {
      const parentDiv = amountInput.closest('.nested-fields');
      return parentDiv && getComputedStyle(parentDiv).display !== 'none';
    });

    const dateInputs = Array.from(this.element.querySelectorAll('.due-at-input.input')).filter(dateInput => {
      const parentDiv = dateInput.closest('.nested-fields');
      return parentDiv && getComputedStyle(parentDiv).display !== 'none';
    });

    let fullPrice = parseFloat(this.fullPriceTarget.value);
    let remainingPrice = fullPrice;

    let isValid = true;
    let isCocoonAdded = false; // Flag to track if any cocoon is added

    const selectedDates = new Set(); // Set to store selected dates

    amountInputs.forEach((amountInput, index) => {
      const dateInput = dateInputs[index];
      const amount = parseFloat(amountInput.value);

      // Add error class if the amount is not valid
      if (isNaN(amount) || amount <= 0 || amount > remainingPrice) {
        // amountInput.classList.add('is-invalid');
        isValid = false;
      } else {
        // amountInput.classList.remove('is-invalid');
      }
      // Check if the date is valid
      if (dateInput && dateInput.value && isValidDate(dateInput.value)) {
        // dateInput.classList.remove('is-invalid');
        const currentDate = new Date(dateInput.value);
        // const options = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' };
        // const formattedDate = currentDate.toLocaleDateString('en-US', options);
        const currentDateStr = `${currentDate.getFullYear()}-${currentDate.getMonth()}-${currentDate.getDate()}`

        // Check if the current date is greater than or equal to the current time
        if (currentDate < new Date()) {
          // dateInput.classList.add('is-invalid');
          isValid = false;
        }
        // Check if the current date is already selected
        else if (selectedDates.has(currentDateStr)) {
          // dateInput.classList.add('is-invalid');
          isValid = false;
        } else {
          selectedDates.add(currentDateStr); // Add the current date to the set
        }
      } else {
        if (dateInput) {
          // dateInput.classList.add('is-invalid');
        }
        isValid = false;
      }

      if (!isNaN(amount))
        remainingPrice -= amount;

      if (amount > 0) {
        isCocoonAdded = true;
      }
    });

    // Show or hide the "add payment" button based on the remaining price and input validity
    const addPaymentButton = this.addPaymentBtnTarget
    // const submitBtn = this.submitBtnTarget
    if (isValid) {
      addPaymentButton.style.display = '';
    } else {
      addPaymentButton.style.display = 'none';
    }

    // Show or hide the "add cocoon" button if no cocoon is added
    if (!isCocoonAdded) {
      addPaymentButton.style.display = '';
    }

    if (remainingPrice === 0 && isValid) {
      addPaymentButton.style.display = 'none';
      // submitBtn.classList.remove('d-none');
    } else {
      // submitBtn.classList.add('d-none');
    }

    this.remainingPriceTarget.value = remainingPrice.toFixed(2);

    const customScheduleDescription = $(this.element).closest('[data-controller="payment-options"]').find('[data-financial-arrangement-target="customScheduleDescription"]');
    if (customScheduleDescription[0]) {
      const paymentLenght = amountInputs?.length
      if (!paymentLenght === 0) {
        customScheduleDescription[0].innerHTML = "None";
      } else if (paymentLenght === 1) {
        customScheduleDescription[0].innerHTML = `${paymentLenght} Payment`;
      } else {
        customScheduleDescription[0].innerHTML = `${paymentLenght} Payments`;
      }
    }
  }
}

function isValidDate(dateString) {
  const date = new Date(dateString);
  return !isNaN(date.getTime());
}

function padZero(num) {
  return num < 10 ? `0${num}` : num;
}