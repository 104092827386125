import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["dateTypeSelect", "codeSelect"];


  connect() {
    if (this.hasDateTypeSelectTarget) {
      $(this.element).find("select:not(.no-select2)").select2();

      datetime_picker.range_input("procedure_date_picker");
      datetime_picker.range_input("plan_date_picker");


      this.change = this.change.bind(this);
      $(this.dateTypeSelectTarget).on("change", this.change);

      const selectedValues = $(this.dateTypeSelectTarget).val()
      togglDateTypeFields(selectedValues)
    }
    this.loadOptions()
  }

  change(event) {
    const selectedValues = $(event.currentTarget).val()
    togglDateTypeFields(selectedValues)
  }

  loadOptions() {
    if (!this.hasCodeSelectTarget)
      return

    const that = this.codeSelectTarget
    $(that).select2({
      allowClear: true,
      dataType: 'json',
      minimumInputLength: 3,
      ajax: {
        url: that.dataset.url,
        type: "GET",
        dataType: "JSON",
        delay: 250,
        data: function (params) {
          return {
            q: params.term
          };
        },
        processResults: function (data) {
          return {
            results: $.map(data, function (value, index) {
              return {
                id: value.id,
                text: value.text
              };
            })
          };
        }
      }
    });
  }
}

function togglDateTypeFields(selectedValues) {
  if (selectedValues.some((x) => x == '3')) {
    $('#procedure_date').show();
    $("#procedure_date :input").prop('disabled', false)

    $("#plan_date :input").prop('disabled', true)
    $('#plan_date').hide();
  } else {
    $('#plan_date').show();
    $("#plan_date :input").prop('disabled', false)

    $("#procedure_date :input").prop('disabled', true)
    $('#procedure_date').hide();
  }
}
