import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index'
import weekSelectPlugin from 'flatpickr/dist/plugins/weekSelect/weekSelect'

( ->
  class DateTimePicker
    init: ->
      $(document).on "turbo:load", ->
        flatpickr '[data-behavior=\'flatpickr\']',
        minTime: 'today'
        enableTime: true
        altFormat: 'F j, Y'
        dateFormat: 'Y-m-d'

      $('.selected_year').change ->
        if $('.selected_month').children('option:selected').val()
          datetime_picker.closed_day_calender();

      $('.selected_month').change ->
        datetime_picker.closed_day_calender();

    time_input: ->
      $(document).on "turbo:load", ->
        $('#restaurant_client_time_zone').val moment().format()
        flatpickr '[data-behavior=\'flatpickr_time\']',
        enableTime: true,
        noCalendar: true,
        dateFormat: "h:i K",
        onValueUpdate: (selectedDates, selectedDate) ->
          office_hours = [9, 10, 11, 12, 13, 14, 15, 16]
          week_days = [1, 2, 3, 4, 5]
          hour = selectedDates[0].getHours()
          d = new Date($('.selected_date')[0].value);
          day = d.getDay()
          if (office_hours.includes(hour)) && (week_days.includes(day))
            $('#red').removeClass 'off-led-red'
            $('#red').addClass 'led-red'
            $('#green').removeClass 'led-green'
            $('#green').addClass 'off-led-green'
          else if (day == 6 || day == 0)
            $('#red').removeClass 'led-red'
            $('#red').addClass 'off-led-red'
            $('#green').removeClass 'off-led-green'
            $('#green').addClass 'led-green'
          else
            $('#red').removeClass 'led-red'
            $('#red').addClass 'off-led-red'
            $('#green').removeClass 'off-led-green'
            $('#green').addClass 'led-green'
          return
          return

    formatto24 = (date) ->
      ampm = date.split(' ')[1]
      time = date.split(' ')[0]
      if ampm == 'PM'
        hours = time.split(':')[0]
        minutes = time.split(':')[1]
        hours24 = JSON.parse(hours) + 12
        hours24 + ':' + minutes
      else
        time

    closed_day_calender: ->
      startDate = moment([$('.selected_year').children('option:selected').val(), $('.selected_month').children('option:selected').val() - 1]);
      endDate = moment(startDate).endOf('month');
      flatpickr '[data-behavior=\'flatpickr_date\']',
      altFormat: 'F j, Y',
      dateFormat: 'Y-m-d',
      inline: true,
      enableTime: false,
      altInput: true,
      static: true,
      minDate: startDate["_d"].getFullYear() + '-' + (startDate["_d"].getMonth() + 1) + '-' + startDate["_d"].getDate()
      maxDate: endDate["_d"].getFullYear() + '-' + (endDate["_d"].getMonth() + 1) + '-' + endDate["_d"].getDate()


    month_input:(trigger_id, submitOnChange) ->
      selector = if trigger_id? then $("##{trigger_id}[data-behavior=\'flatpickr_month\']") else $("[data-behavior=\'flatpickr_month\']")
      return unless selector.length

      $(document).on "turbo:load", ->
        flatpickr selector,
        plugins: [new monthSelectPlugin({})],

        onReady: () ->
          this.setDate($(this.element).data("defaultDate"), false, $(this.element).data("altFormat"));

        onChange: (selectedDates, dateStr, instance) ->
          $(this.element).closest("form").get(0).requestSubmit() if (submitOnChange?)

        onValueUpdate: () ->
         $('#'+ trigger_id).val($('#' + trigger_id).val()).change() if trigger_id?

      $(document).ready ->
        flatpickr selector,
        plugins: [new monthSelectPlugin({})],

        onReady: () ->
          this.setDate($(this.element).data("defaultDate"), false, $(this.element).data("altFormat"));

        onChange: (selectedDates, dateStr, instance) ->
          $(this.element).closest("form").get(0).requestSubmit() if (submitOnChange?)

        onValueUpdate: () ->
         $('#'+ trigger_id).val($('#' + trigger_id).val()).change() if trigger_id?

    week_input:(trigger_id, submitOnChange) ->
      selector = if trigger_id? then $("##{trigger_id}[data-behavior=\'flatpickr_week\']") else $("[data-behavior=\'flatpickr_week\']")
      return unless selector.length

      $(document).on "turbo:load", ->
        flatpickr selector,
        locale: firstDayOfWeek: 1
        plugins: [new weekSelectPlugin({})],

        onChange: (selectedDates, dateStr, instance) ->
          $(this.element).closest("form").get(0).requestSubmit() if (submitOnChange?)

        onValueUpdate: () ->
         $('#'+ trigger_id).val($('#' + trigger_id).val()).change() if trigger_id?

      $(document).ready ->
        flatpickr selector,
        locale: firstDayOfWeek: 1
        plugins: [new weekSelectPlugin({})],

        onChange: (selectedDates, dateStr, instance) ->
          $(this.element).closest("form").get(0).requestSubmit() if (submitOnChange?)

        onValueUpdate: () ->
         $('#'+ trigger_id).val($('#' + trigger_id).val()).change() if trigger_id?

    range_input:(trigger_id, submitOnChange=false) ->
      selector = if trigger_id? then $("##{trigger_id}[data-behavior=\'flatpickr_range\']") else $("[data-behavior=\'flatpickr_range\']")
      return unless selector.length

      $(document).on "turbo:load", ->
        flatpickr selector,
        mode: "range",
        dateFormat: "Y-m-d",
        onReady: () ->
          this.setDate($(this.element).data("defaultDate"), false, $(this.element).data("altFormat"));
          this.set("defaultDate", [null, null]);

        onChange: (selectedDates, dateStr, instance) ->
          $(this.element).closest("form").get(0).requestSubmit() if (selectedDates.length == 2  and submitOnChange)

        onOpen: (selectedDates, dateStr, instance) ->
          instance.clear(false, 0) if (selectedDates.length == 1)

        onValueUpdate: () ->
          $('#'+ trigger_id).val($('#' + trigger_id).val()).change() if trigger_id?

      $(document).ready ->
        flatpickr selector,
        mode: "range",
        dateFormat: "Y-m-d",
        onReady: () ->
          if $(this.element).data("defaultDate")
            this.setDate($(this.element).data("defaultDate"), false, $(this.element).data("altFormat"));
            this.set("defaultDate", [null, null]);

        onChange: (selectedDates, dateStr, instance) ->
          $(this.element).closest("form").get(0).requestSubmit() if (selectedDates.length == 2  and submitOnChange)

        onOpen: (selectedDates, dateStr, instance) ->
          instance.clear(false, 0) if (selectedDates.length == 1)

        onValueUpdate: () ->
          $('#'+ trigger_id).val($('#' + trigger_id).val()).change() if trigger_id?

    day_input: ->

    date_input: ->
      $(document).on "turbo:load", ->
        flatpickr '[data-behavior=\'flatpickr_date\']',
        altFormat: 'F j, Y',
        dateFormat: 'Y-m-d',
        inline: true,
        enableTime: false,
        altInput: true,
        static: true,
        onValueUpdate: (selectedDates, selectedDate) ->
          day = selectedDates[0].getDay()
          office_hours = [9, 10, 11, 12, 13, 14, 15, 16]
          week_days = [1, 2, 3, 4, 5]
          time = formatto24($('.selected_time')[0].value)
          hour = parseInt(time.substring(0,2))
          if (day == 6 || day == 0) && !(isNaN(hour))
            $('#red').removeClass 'led-red'
            $('#red').addClass 'off-led-red'
            $('#green').removeClass 'off-led-green'
            $('#green').addClass 'led-green'
          else if !(office_hours.includes(hour)) && (week_days.includes(day)) && !(isNaN(hour))
            $('#red').removeClass 'led-red'
            $('#red').addClass 'off-led-red'
            $('#green').removeClass 'off-led-green'
            $('#green').addClass 'led-green'
          else
            $('#red').removeClass 'off-led-red'
            $('#red').addClass 'led-red'
            $('#green').removeClass 'led-green'
            $('#green').addClass 'off-led-green'
          return
          return

  window.datetime_picker = new DateTimePicker();
).call this
